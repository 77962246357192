<template>
  <div v-if="displayForm">
    <template v-if="storyFermee">
      <br />
      <div class="message is-warning">
        <div class="message-body">
          <p v-if="story.meta.messageFermeture" v-html="story.meta.messageFermeture"></p>
          <p v-else>Cette discussion est fermée.</p>
        </div>
      </div>
      <br />
    </template>
    <template v-else>
      <template v-if="estBloque()">
        <br />
        <div class="message is-danger">
          <div
            class="message-body"
          >Votre compte est bloqué, vous ne pouvez plus participer aux discussions</div>
        </div>
        <br />
      </template>
      <template v-else>
        <template v-if="isOpened">
          <div id="post" class="hero" @keydown.esc.prevent.stop="closeForm">
            <form v-if="this.isConnected" @submit.prevent="post">
              <div class="field">
                <label class="label">{{titre}}</label>
                <div class="control saisie-message">
                  <div class="pasting-message" v-if="pasting">
                    <span>Chargement du contenu depuis le presse-papier. Veuillez patienter</span>
                  </div>
                  <a class="previsu" @click="previsuToggle" v-if="!afficherPrevisu">Prévisualiser</a>
                  <textarea
                    :disabled="loading || pasting"
                    ref="postTextarea"
                    @paste="paste"
                    @keydown.ctrl.enter.prevent.stop="post"
                    class="textarea"
                    v-model="body"
                    placeholder="Rédigez ici votre message"
                  ></textarea>
                </div>
                <p v-if="error" class="help is-danger">{{error}}</p>
              </div>
              <div class="message" v-if="afficherPrevisu">
                <div class="message-header">
                  <p>Aperçu du message</p>
                  <button class="delete" type="button" aria-label="delete" @click="previsuToggle"></button>
                </div>
                <div class="message-body" v-html="bodyPrevisu"></div>
              </div>

              <captcha v-if="body && !$store.getters.captcha" />
              <div v-else class="field is-grouped">
                <div class="control">
                  <button class="button is-link with-hint" :class="loading ? 'is-loading' : ''">
                    {{cta}}
                    <span>Ctrl+Entrée</span>
                  </button>
                </div>
                <div v-if="parentId" class="control">
                  <button type="button" @click="closeForm" class="button is-light with-hint">
                    Annuler
                    <span>Esc</span>
                  </button>
                </div>
                <div class="control" v-if="!afficherAide">
                  <button type="button" class="button is-text" @click="aide">Aide</button>
                </div>
              </div>
              <!-- must be blank / hidden by css-->
              <input type="text" ref="content" name="content" value />
              <!-- must be blank / hidden by css position-->
              <textarea ref="message" name="message"></textarea>
              <!-- must be set to none -->
              <input type="text" ref="user" name="user" v-show="false" value="none" />
              <comment-aide v-if="afficherAide" />
            </form>
            <template v-else>
              <i>Connectez-vous pour poster un message.</i>
            </template>
          </div>
        </template>
        <template v-else>
          <br />
        </template>
      </template>
    </template>
  </div>
</template>
<script>
import CommentAide from "@/components/Comments/CommentAide.vue";
import Captcha from "@/components/Divers/Captcha.vue";
export default {
  components: { CommentAide, Captcha },
  name: "Post",
  props: ["parentId"],
  data() {
    return {
      afficherAide: false,
      afficherPrevisu: false,
      pasting: false,
      error: "",
      body: "",
      bodyPrevisu: "",
      loading: false,
    };
  },
  watch: {
    body() {
      this.getPrevisu();
      this.$store.commit("setBrouillon", {
        storyId: this.story.storyId,
        parentId: this.parentId,
        brouillon: this.body,
      });
    },
  },
  mounted() {
    /**
     * Annuler la saisie
     */
    this.$bus.$on("close-form", this.closeForm);
    /**
     * gestion de la fenetre d'aide
     */
    this.$bus.$on("masquer-aide", () => {
      this.afficherAide = false;
    });
    /**
     * Dans le cas de la rédaction d'une réponse, on donne le focus au champ de saisie
     */
    if (this.parentId) {
      setTimeout(() => {
        this.$refs["postTextarea"].focus();
        // this.scrollToElement(this.$refs["postTextarea"])
      }, 100);
    }
    /**
     * Gestion d'un eventuel brouillon
     */
    let brouillon = this.$store.getters.brouillon({
      storyId: this.story.storyId,
      parentId: this.parentId,
    });
    if (brouillon) {
      this.body = brouillon;
    } else if (this.$store.getters.quote) {
      this.body = this.$store.getters.quote;
    }
  },
  computed: {
    isOpened() {
      if (this.isReduced && !this.parentId) {
        return this.$store.state.openPost;
      } else {
        return true;
      }
    },
    titre() {
      return this.parentId ? "Répondre à ce message" : "Réagir à cet article";
    },
    cta() {
      return this.parentId ? "Poster votre réponse" : "Poster ce message";
    },
    displayForm() {
      return !this.parentId || this.parentId == this.$store.getters.replyTo;
    },
  },
  methods: {
    getPrevisu() {
      if (window.st_getPrevisu) {
        clearTimeout(window.st_getPrevisu);
      }
      window.st_getPrevisu = setTimeout(() => {
        if (this.afficherPrevisu) {
          api.post("comment/preview", { body: this.body }).then((response) => {
            this.bodyPrevisu = response.data.body;
          });
        }
      }, 1000);
    },
    previsuToggle() {
      this.afficherPrevisu = !this.afficherPrevisu;
      this.getPrevisu();
    },
    aide() {
      this.afficherAide = true;
    },
    paste(event) {
      let items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      for (let index in items) {
        let item = items[index];
        if (item.kind === "file") {
          if (item.type.includes("image")) {
            this.pasting = true;
            let blob = item.getAsFile();
            let data = new FormData();
            data.append("blob", blob);

            let config = {
              header: {
                "Content-Type": "multipart/form-data",
              },
            };

            api.post("blob", data, config).then((response) => {
              this.insertSomething(response.data.bbcode);
              this.pasting = false;
            });
          }
        }
      }
    },
    closeForm() {
      // this.$bus.$emit("replyTo", false);
      this.body=''; 
      this.$store.commit("deleteBrouillon", {
        storyId: this.story.storyId,
        parentId: this.parentId,
      });
      this.$store.commit("setReply", false);
    },
    okToPost() {
      this.error = "";

      let bot = false;
      if (this.$refs["content"].value) {
        bot = true;
      }
      if (this.$refs["message"].value) {
        bot = true;
      }
      if (this.$refs["user"].value != "none") {
        bot = true;
      }
      if (bot) {
        this.error = "Vous ne pouvez pas poster de message.";
      } else {
        if (!this.$store.getters.captcha) {
          this.error =
            "Le captcha n'est pas encore résolu ou a déterminé que vous ne pouvez pas poster ce message.";
        } else if (!this.body) {
          this.error = "Le message est vide. Faites un effort.";
        }
      }

      return this.error ? false : true;
    },
    post() {
      if (!this.loading) {
        if (this.okToPost()) {
          this.loading = true;
          api
            .post(`story/${this.story.storyId}/comment`, {
              body: this.body,
              parentId: this.parentId,
              member: this.$store.state.member.name,
            })
            .then((response) => {
              let payload = {
                storyComments: response.data.story.comments,
                memberComments: response.data.member.comments,
                memberTrueComments: response.data.member.trueComments,
              };
              this.evenement("afterPost", payload);

              this.body = "";
              let comment = response.data.comment;

              if (comment.parentId) {
                this.$bus.$emit("add-comment-" + comment.parentId, comment);
              } else {
                this.$bus.$emit("add-comment-racine", comment);
                // this.$bus.$emit("load-story", payload.storyComments < 1000);
              }
                this.$store.commit("setScrollIntoView", comment.id)

            })
            .catch((error) => {
              console.log(error);
              if (error.response.data.event) {
                this.triggerEvent(
                  error.response.data.event,
                  error.response.data.payload
                );
              }
              alert(error.response.data.message);
            })
            .finally(() => {
              this.$store.commit("deleteBrouillon", {
                storyId: this.story.storyId,
                parentId: this.parentId,
              });
              this.loading = false;
              this.closeForm();
            });
        }
      }
    },
    insertSomething(insert) {
      let tArea = this.$refs["postTextarea"];
      // filter:
      if (0 == insert) {
        return;
      }
      if (0 == cursorPos) {
        return;
      }

      // get cursor's position:
      let startPos = tArea.selectionStart,
        endPos = tArea.selectionEnd,
        cursorPos = startPos,
        tmpStr = tArea.value;

      // insert:
      this.body =
        tmpStr.substring(0, startPos) +
        insert +
        tmpStr.substring(endPos, tmpStr.length);

      // move cursor:
      setTimeout(() => {
        cursorPos += insert.length;
        tArea.selectionStart = tArea.selectionEnd = cursorPos;
      }, 10);
    },
  },
};
</script>
<style scoped lang="scss">
#post {
  margin: 1em 0;
  margin-bottom: 2em;
}
button {
  span {
    display: inline-block;
    overflow: hidden;
    margin-left: 0.5em;
    transition: max-width 0.3s ease;
    width: auto;
    max-width: 0;
    opacity: 0.8;
    font-family: monospace;
    font-size: smaller;
  }
  &:hover span {
    margin-right: 0.5em;
    max-width: 600px;
  }
}
[name="content"] {
  display: none;
}
[name="message"] {
  width: 0;
  height: 0;
  border: none;
  position: absolute;
  top: -1000%;
  left: -1000%;
}
.saisie-message {
  .pasting-message {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;
    width: 100%;
    height: 100%;
    span {
      font-weight: bold;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
    }
  }
  .previsu {
    border-bottom-left-radius: 5px;
    background: white;
    font-size: smaller;
    z-index: 1;
    position: absolute;
    top: 1px;
    right: 0.4em;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  &:hover {
    .previsu {
      opacity: 1;
    }
  }
}
</style>