<template>
  <nav class="level is-mobile actions comment-actions">
    <div class="level-left">
      <votes :comment="comment" />
      <comment-admin-actions :comment="comment" />
    </div>
    <div class="level-right">
      <a
        v-if="comment.ancestor"
        class="level-item"
        target="_top"
        @click="scrollToComment(comment.ancestor)"
        aria-label="Aller au commentaire parent"
        data-balloon-pos="up"
      >
        <span class="icon is-small">
          <i class="fas fa-arrow-up rotation-45deg"></i>
        </span>
      </a>
      <a
        target="_top"
        class="level-item"
        aria-label="Lien vers ce commentaire"
        data-balloon-pos="left"
        @click="directLink"
      >
        <span class="icon is-small">
          <i class="fas fa-link"></i>
        </span>
      </a>

      <a
        v-if="mine"
        @click="edit"
        class="level-item"
        :aria-label="editable ? 'Editer ce message' : 'Vous ne pouvez plus éditer ce message (Le délai est dépassé)'"
        data-balloon-pos="left"
      >
        <span class="icon is-small">
          <i class="fas fa-pen"></i>
        </span>
      </a>
      <a
        v-if="!storyMeta('fermer') && !estBloque()"
        class="level-item"
        aria-label="Répondre à ce message"
        data-balloon-pos="left"
        @mousedown="quoteReply"
      >
        <span class="icon is-small">
          <i class="fas fa-reply"></i>
        </span>
      </a>
    </div>
  </nav>
</template>
<script>
import Votes from "@/components/Comments/Actions/Votes.vue";
import CommentAdminActions from "@/components/Comments/CommentAdminActions.vue";
export default {
  name: "CommentActions",
  props: ["comment", "niveau"],
  components: { Votes, CommentAdminActions },
  methods: {
    directLink() {
      this.triggerEvent("directLink", this.comment.id);
      this.scrollToComment(this.comment.id);
    },
    quoteReply() {
      let quote = this.getSelectionText(this.comment.id);
      this.$bus.$emit("replyTo", this.replyId, quote);
    },
    edit() {
      if (this.editable) {
        this.$store.commit("setEdit", this.comment.id);
      } else {
        alert("Vous ne pouvez plus éditer ce message (Le délai est dépassé)");
      }
    },
  },
  computed: {
    replyId() {
      if (this.niveau > 2) {
        return this.comment.parentId;
      } else {
        return this.comment.id;
      }
    },

    editable() {
      return this.mine && (this.comment.editable || this.isAdmin);
    },
    mine() {
      return this.comment.memberId == this.loggedMember("id");
    },
  },
};
</script>
<style lang="scss">
.comment-actions {
  .admin-actions {
    padding-left: 0.75rem;
    border-left: 1px solid #ccc;
  }

  .level-item .icon-text {
    .texte {
      display: none;
    }
    &:hover {
      .texte {
        display: block;
      }
    }
  }

  .rotate-45 {
    transform: rotate(45deg);
  }
}
</style>