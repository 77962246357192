import { render, staticRenderFns } from "./CommentsChargement.vue?vue&type=template&id=7ebc8214&"
import script from "./CommentsChargement.vue?vue&type=script&lang=js&"
export * from "./CommentsChargement.vue?vue&type=script&lang=js&"
import style0 from "./CommentsChargement.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports