<template>
  <span class="level-item votes">
    <a
      @click="upvote"
      class="level-item upvote"
      :class="isUpvoted ? 'voted' : ''"
      :aria-label="isUpvoted ? 'Retirer votre vote' : '+1 (C\'est oui)'"
      data-balloon-pos="up"
    >
      <span class="icon ">
        <i class="fas fa-plus-circle"></i>
      </span>
    </a>
    <a
      @click="downvote"
      class="level-item downvote"
      :class="isDownvoted ? 'voted' : ''"
      :aria-label="isDownvoted ? 'Retirer votre vote' : '-1 (C\'est non)'"
      data-balloon-pos="up"
    >
      <span class="icon ">
        <i class="fas fa-minus-circle"></i>
      </span>
    </a>
    <span
      v-if="comment.rating>0"
      class="level-item"
      :aria-label="pluriel(comment.upvotes,'vote positif','aucun')+' et '+pluriel(comment.downvotes,'vote négatif','aucun')"
      data-balloon-pos="right"
    >{{comment.rating}}</span>
  </span>
</template>
<script>
export default {
  name: "Votes",
  props: ["comment"],
  data() {
    return {
      isUpvoted: false,
      isDownvoted: false,
    };
  },
  mounted() {
    this.$bus.$on("upvote-" + this.comment.id, (vote = null) => {
    //   console.log("upvote-" + this.comment.id, vote);
      vote = vote == null ? !this.isUpvoted : vote;
    //   console.log(vote);
      this.isUpvoted = vote;
      if (this.isUpvoted) {
        this.isDownvoted = false;
      }
    });
	
    this.$bus.$on("downvote-" + this.comment.id, (vote = null) => {
    //   console.log("downvote-" + this.comment.id, vote);
      vote = vote == null ? !this.isDownvoted : vote;
    //   console.log(vote);
      this.isDownvoted = vote;
      if (this.isDownvoted) {
        this.isUpvoted = false;
      }
    });
  },
  computed: {
    /*    isUpvoted() {
		console.log('isUpvoted')
        let votes = this.$store.getters.upvotes(this.comment.storyId);
        return votes.indexOf(this.comment.id) > -1;
    },
    isDownvoted() {
		console.log('isDownvoted')
        let votes = this.$store.getters.downvotes(this.comment.storyId);
        return votes.indexOf(this.comment.id) > -1;
    },*/
  },
  methods: {
    upvote() {
      if (this.story.meta.fermer || this.estBloque()) return;
      api
        .post(`story/${this.comment.storyId}/upvote/${this.comment.id}`)
        .then((response) => {
          this.$bus.$emit("upvote-" + this.comment.id);
          this.handleResponse(response.data);
        });
    },
    downvote() {
      if (this.story.meta.fermer || this.estBloque()) return;
      api
        .post(`story/${this.comment.storyId}/downvote/${this.comment.id}`)
        .then((response) => {
          this.$bus.$emit("downvote-" + this.comment.id);
          this.handleResponse(response.data);
        });
    },
    handleResponse(data) {
      //   this.upvoted = false;
      //   this.downvoted = false;
      this.$store.commit("setVotes", {
        storyId: data.comment.storyId,
        votes: data.votes,
      });
      //      this.$bus.$emit("update-comment", data.comment);
      this.$bus.$emit("update-comment-fields", {
        id: data.comment.id,
        rating: data.comment.rating,
      });
    },
  },
};
</script>
<style scoped>

.upvote.voted {
  color: green !important;
}
.downvote.voted {
  color: red !important;
}

</style>