<template>
	<div id="edit" class="hero" @keydown.esc.prevent.stop="closeForm" >
		<form @submit.prevent="post">
			<div class="field">
				<label class="label">Editer ce message</label>
				<div class="control">
					<textarea @keydown.ctrl.enter.prevent.stop="post" class="textarea" v-model="body" placeholder="Rédigez ici votre message"></textarea>
				</div>
				<p v-if="error" class="help is-danger">{{error}}</p>
			</div>

			<div class="field is-grouped">
				<div class="control">
					<button class="button is-link with-hint" :class="loading ? 'is-loading' : ''">Enregistrer<span>Ctrl+Entrée</span></button>
				</div>
				<div class="control">
					<button type="button" @click="closeForm" class="button is-light with-hint">Annuler<span>Esc</span></button>
				</div>
			</div>
		</form>	

	</div>

</template>
<script>
	export default {
		name : 'Edit',
		props:['comment'],
		data() {
			return {
				error:'',
				body:'',
				original:'',
				loading:false
			}
		},
		mounted(){
			this.original = this.comment.original
			this.body = this.original;
		},
		methods : {
			closeForm() {
				this.body=this.original;
				this.$store.commit('setEdit',false);
			},
			okToPost() {
				if(!this.body) {
					this.error='Le message est vide. Faites un effort.';
					return false;
				}
				this.error='';
				return true;
			},
			post() {
				if(!this.loading) {
					if(this.okToPost()) {
						this.loading=true;
						api.patch(`story/${this.comment.storyId}/comment/${this.comment.id}`,{
							body:this.body,
							id:this.comment.id
						}).then(response => {
							this.body='';
							this.original = response.data.body;
							this.$bus.$emit('load-story');
						}).finally(() => {
							this.loading=false;
							this.closeForm();
						})
					}
				}
			}
		}
	}
</script>
<style scoped lang="scss">
#edit {
	margin:1em 0;
	margin-bottom: 2em;
}
</style>