<template>
  <div class="actions actions-top" v-if="afficherActions">
    <a
      v-if="!folded"
      class="level-item"
      aria-label="Replier cette conversation"
      data-balloon-pos="left"
      @click="$bus.$emit('fold-comment-'+comment.id)"
    >
      <span class="icon is-small">
        <i class="fas fa-caret-circle-up"></i>
      </span>
    </a>
    <a
      v-else
      class="level-item"
      aria-label="Déplier cette conversation"
      data-balloon-pos="left"
      @click="$bus.$emit('fold-comment-'+comment.id)"
    >
      <span class="icon is-small">
        <i class="fas fa-caret-circle-down"></i>
      </span>
    </a>
  </div>
</template>

<script>
export default {
  props: ["comment", "folded"],
  computed: {
    afficherActions() {
      return (
        (!this.comment.parentId || this.comment.parentId == "0") &&
        this.comment.answers && this.comment.answers.length
      );
    },
  },
};
</script>

<style>
.actions-top {
    position: absolute;
    right: 0;
}
</style>