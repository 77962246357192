<template>
  <div class="content">
    <span>Êtes-vous un robot ?</span>
    &nbsp;<a @click="robot"><u>oui</u></a>&nbsp;
    <a @click="setCaptcha"><u>non</u></a>
  </div>
</template>

<script>
export default {
  methods: {
    robot() {
        this.$bus.$emit("close-form")
    },
    setCaptcha() {
      setTimeout(() => this.$store.commit("setCaptcha"), 100);
    },
  },
};
</script>

<style>
</style>