<template>
  <div class="avatar" v-if="afficherAvatar">
    <img v-if="avatar" :src="avatar" />
    <avatar-initiales :member="memberName" :initiales="memberInitiales" v-else />
  </div>
</template>

<script>
import AvatarInitiales from "@/components/Divers/AvatarInitiales.vue";
export default {
  props: ["comment", "member", "admin"],
  components: { AvatarInitiales },
  methods: {},
  computed: {
    afficherAvatar() {
      if (this.comment) {
        let params = { default: true, meta: this.comment.memberMeta };
        if(this.memberMeta("peutAfficherAvatar", params)) {
          if(!this.memberMeta("masquerAvatar", {meta: this.comment.memberMeta })) {
            return true;
          }
        }
      } else {
        return true;
      }
    },
    memberInitiales() {
      if (this.comment) {
        return this.comment.memberInitiales;
      } else if (this.member) {
        return this.member.initiales;
      }
    },
    memberName() {
      if (this.comment) {
        return this.comment.member;
      } else if (this.member) {
        return this.member.name;
      } else {
        return this.loggedMember("name");
      }
    },
    avatar() {
      if (this.comment) {
        if (this.comment.avatar) {
          return this.comment.avatar;
        }
      } else if (this.member) {
        if (this.member.avatar) {
          return this.member.avatar;
        }
      } else {
        return this.loggedMember("avatar");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  width: 32px;
  height: 32px;
  font-size: 80%;
  @media only screen and (min-width: 530px) {
    width: 48px;
    height: 48px;
    font-size: 100%;
  }
  overflow: hidden;
  border-radius: 50%;
  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}
</style>