<template>
  <span class="level-item alerte" v-if="isConnected">
    <a
      @click="alerter"
      class="level-item"
      aria-label="Envoyer une alerte aux modérateurs"
      data-balloon-pos="right"
    >
      <span class="icon is-small">
        <i class="fas fa-comment-alt-exclamation"></i>
      </span>
    </a>
  </span>
</template>

<script>
export default {
  props: ["comment"],
  methods: {
    alerter() {
 //     let message = 'Votre alerte dans la discussion {{story.title}} a été envoyée.';
      let uniqueId = this.comment.id;
      let payload = {
        comment: this.comment,
        member: this.$store.state.member,
        story: this.story,
      };
      api.post("notification", { name: "alerte", uniqueId, payload });
      this.evenement("reportComment", payload);
    },
  },
};
</script>

<style lang="scss">
span.alerte {
  margin-top: 0.5em;
  opacity: 0;
  a {
    color: #aaa;
  }
}
.commentaire:hover {
  span.alerte {
    opacity: 1;
  }
}
</style>