<template>
  <div v-if="commentaireVisible" class="commentaire-wrapper">
    <div
      class="commentaire"
      :class="cssClasses"
      :data-id="comment.id"
      :ref="comment.id"
      v-if="afficherCommentaire"
    >
      <a :name="'comment-'+comment.id"></a>
      <div class="contenu box">
        <div class="media">
          <figure class="media-left">
            <avatar :comment="comment" />
            <alerte :comment="comment" />
          </figure>
          <div class="media-content">
            <div class="content">
              <comment-actions-top
                v-if="actions != 'false'"
                :comment="comment"
                :folded="folded"
                :niveau="niveau"
              />
              <div class="member-name">
                <div>
                  <span class="name mr-2" v-html="memberNameClean" :title="comment.member"></span>
                  <member-status :member="comment" />
                  <member-level :member="comment" />
                </div>
                <div
                  class="date"
                  :aria-label="dateHeureFormatee(comment.createdAt)"
                  data-balloon-pos="right"
                >{{timeSince(comment.createdAt)}}</div>
              </div>

              <div class="body">
                <template v-if="comment.quotedComment">
                  <blockquote>
                    <button @click="scrollToComment(comment.quotedComment.id)" />
                    <h3 class="title is-6">Message posté par {{comment.quotedComment.member}}</h3>
                    <div v-html="comment.quotedComment.body" />
                  </blockquote>
                </template>
                <div v-html="body" />
              </div>

              <comment-actions
                v-if="actions != 'false'"
                :comment="comment"
                :folded="folded"
                :niveau="niveau"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommentMasque v-else :comment="comment" />
    <CommentsAnswers
      :comment="comment"
      :folded="folded"
      v-if="hasAnswers"
      :class="parent ? 'parent' : ''"
      :niveau="Number(niveau)+1"
    />
    <template v-if="!disabled">
      <template v-if="comment.id == $store.getters.edit">
        <edit :comment="comment" />
      </template>
      <template v-if="comment.id == $store.getters.replyTo">
        <post :parentId="comment.id" />
      </template>
    </template>
  </div>
</template>

<script>
import Post from "@/components/Comments/Actions/Post.vue";
import Edit from "@/components/Comments/Actions/Edit.vue";
import Comment from "@/components/Comments/Comment.vue";
import CommentMasque from "@/components/Comments/CommentMasque.vue";
import CommentActions from "@/components/Comments/CommentActions.vue";
import CommentActionsTop from "@/components/Comments/CommentActionsTop.vue";
import CommentsAnswers from "@/components/Comments/CommentsAnswers.vue";
import Alerte from "@/components/Comments/Actions/Alerte.vue";
import Avatar from "@/components/Divers/Avatar.vue";
export default {
  name: "Comment",
  components: {
    Comment,
    CommentMasque,
    CommentActions,
    CommentActionsTop,
    CommentsAnswers,
    Alerte,
    Post,
    Edit,
    Avatar,
  },
  props: [
    "comment",
    "actions",
    "child",
    "parent",
    "niveau",
    "disabled",
  ],
  data() {
    return {
      efface: false,
      hidden: false,
      replyTo: false,
      folded: false,
      enAvant: false,
    };
  },
  mounted() {

    this.$bus.$on("commentaire-efface-" + this.comment.id, () => {
      this.efface = true;
    });
    this.$bus.$on("commentaire-hidden-" + this.comment.id, () => {
      this.hidden = !this.hidden;
    });

    this.$bus.$on("fold-comment-" + this.comment.id, this.foldComment);
    this.folded = this.isFolded(this.comment);
    if (this.$store.getters.scrollIntoView == this.comment.id) {
      this.$store.commit("setScrollIntoView", false);
      this.mettreEnAvant();
      this.scrollToComment(this.comment.id);

      /*      document.querySelector('[data-id="' + this.comment.id + '"]').scrollIntoView({
            block: 'center',
            inline: 'center'
        });*/
    }
  },
  methods: {
    mettreEnAvant() {
      this.enAvant = true;
      setTimeout(() => (this.enAvant = false), 5000);
    },
    foldComment() {
      this.folded = !this.folded;
      this.setFolded(this.comment, this.folded);
    },
  },
  computed: {
    commentaireVisible() {
      if (!this.efface) {
        if (this.niveau == undefined || this.niveau <= 3) {
          return true;
        }
      }
    },
    memberNameClean() {
      // return 'un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-'
      return this.comment.member;
      /*      let len=50;
      let invisbleSpace = "&ZeroWidthSpace;";
      let member = this.comment.member;
      member = 'un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-un-nom-'
      console.log(member.length)
      if (member.length > len) {
        member = "<span>" + member.substr(0, len) + "&hellip;</span>";
      }
      return member;*/
    },
    afficherCommentaire() {
      return (
        this.isAdmin ||
        (!this.comment.hidden &&
          !this.comment.memberHidden &&
          !this.comment.ip_bloquee)
      );
    },
    displayComment() {
      if (this.comment.hidden) {
        return true;
      }
      return false;
    },
    cssClasses() {
      let classes = [];
      classes.push("niveau-" + this.niveau);
      if (this.enAvant || this.focused) {
        classes.push("enAvant");
      }
      if (this.hasAnswers) {
        classes.push("hasAnswers");
      }
      if (this.comment.ip_bloquee) {
        classes.push("ip-bloquee");
      }
      if (this.comment.memberHidden) {
        classes.push("membre-masque");
      }
      if (this.parent) {
        // if (!this.comment.parentId || this.comment.parentId == "0") {
        classes.push("parent");
      }
      if (this.commentHidden) {
        if (this.isAdmin) {
          classes.push("hidden-admin");
        } else {
          classes.push("hidden");
        }
      }
      return classes.join(" ");
    },

    commentHidden() {
      if (this.hidden) {
        return true;
      }
      if (this.comment.hidden) {
        return true;
      }
    },
    focused() {
      return this.$store.state.focused == this.comment.id;
    },
    body() {
      return this.comment.body + "<br>";
    },
    isParent() {
      return this.comment["parentId"] == "0";
    },
    hasAnswers() {
      return this.comment.answers && this.comment.answers.length;
    }
  },
};
</script>

<style lang="scss">
.commentaire {
  &.parent {
    margin-top: 2em;
  }
  .contenu {
    position: relative;
    padding: 0.75rem;
    @media only screen and (min-width: 530px) {
      padding: 1rem;
    }

    transition: box-shadow 0.3s ease;
    box-shadow: none;
    border: 1px solid #ccc;
    border-top: 1px solid #eee;
    border-left: 1px solid #333;
    // box-shadow: 0 0.1em 0.5em -0.125em rgba(10, 10, 10, 0.2);
    &:hover {
      // box-shadow: 0 0.1em 0.5em -0.125em rgba(10, 10, 10, 0.5);
    }
  }
}
.answers.parent {
  // font-size: 85%;
  .avatar {
    width: 32px;
    height: 32px;
  }
}

.commentaire .contenu {
  background: rgba(200, 200, 200, 0.2);
}
.commentaire.enAvant .contenu {
  background: rgba(200, 150, 00, 0.1);
}

.commentaire {
  .member-name {
    overflow: hidden;
    border-bottom: 1px solid white;
    padding-bottom: 1em;
    margin-bottom: 1em;
  }

  .body {
    .spoiler {
      background: white;
      padding: 1em;
      position: relative;
      transition: filter 0.2s ease;
      filter: blur(0.2em);
      &:hover {
        filter: blur(0);
      }
    }
    margin-bottom: 1em;
    img[src*="/images/"], img[data-image] {
      max-height: 500px;
      background: white;
      box-shadow: 0 0.1em 0.5em -0.125em rgba(10, 10, 10, 0.2);
      padding: 1em;
      border-radius: 5px;
      margin-top: 1em;
    }
    blockquote {
      max-height: 300px;
      overflow-y: auto;
      position: relative;
      button {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
      }
      background: white;
      margin-top: 1em;
      & + br {
        display: none;
      }
    }
  }
  /*  margin-top: 0.5em;
  margin-bottom: 0.5em;*/
  position: relative;
  .contenu {
    transition: opacity 0.5 ease, background 1s ease;
    opacity: 1;
  }
  &.membre-masque .contenu {
    &:before {
      content: "Membre masqué";
      position: absolute;
      right: 0.5em;
      top: 0.5em;
      background: #ff2f00;
      color: white;
      padding: 3px 5px;
      font-size: 12px;
    }
    background-image: linear-gradient(
      45deg,
      #fff5aa 25%,
      #ffffff 25%,
      #ffffff 50%,
      #fff5aa 50%,
      #fff5aa 75%,
      #ffffff 75%,
      #ffffff 100%
    );
    background-size: 56.57px 56.57px;
  }
  &.ip-bloquee .contenu {
    &:before {
      content: "Ip bloquée";
      position: absolute;
      right: 0.5em;
      top: 0.5em;
      background: #ff2f00;
      color: white;
      padding: 3px 5px;
      font-size: 12px;
    }
    background-image: linear-gradient(
      45deg,
      #ffe1d9 25%,
      #ffffff 25%,
      #ffffff 50%,
      #ffe1d9 50%,
      #ffe1d9 75%,
      #ffffff 75%,
      #ffffff 100%
    );
    background-size: 56.57px 56.57px;
  }

  &.hidden-admin .contenu {
    &:before {
      content: "Commentaire masqué";
      position: absolute;
      right: 0.5em;
      top: 0.5em;
      background: #ff2f00;
      color: white;
      padding: 3px 5px;
      font-size: 12px;
    }
    background-image: linear-gradient(
      45deg,
      #85fff5 25%,
      #ffffff 25%,
      #ffffff 50%,
      #85fff5 50%,
      #85fff5 75%,
      #ffffff 75%,
      #ffffff 100%
    );
    background-size: 56.57px 56.57px;
  }
  .focused {
    border: 1px solid #888;
    background: #ffded8;
  }

  .actions {
    a {
      color: #aaa;
      transition: color 0.5s ease;
    }
    a:hover {
      color: black;
    }
  }
  .media {
    .media-content {
      min-width: 0; // https://css-tricks.com/flexbox-truncated-text/
      overflow: initial;
      .content {
        position: relative;
      }
    }
  }
  .member-name {
    .name {
      display: inline-block;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 30px);
      @media screen and (min-width: 500px) {
        max-width: 55%;
      }
    }
  }
  .date {
    opacity: 0.7;
    @media screen and (min-width: 500px) {
      margin-top: -0.5em;
    }
    display: block;
    font-size: smaller;
  }
}

.answers {
  margin-left: 2vw;
  @media only screen and (min-width: 530px) {
    position: relative;
    margin-left: 3vw;
  }
  .commentaire {
    margin-bottom: 0;
  }
}

/*.member-name strong {
  display: block;
}
.member-name .dash {
  display: none;
}*/
/*@media only screen and (min-width: 530px) {
  .member-name .name {
    display: inline;
  }
  .member-name .dash {
    display: inline;
  }
}*/
</style>
