<template>
  <div class="avatar-initiales">
    <span class="fond" :style="{background:couleurFond}"></span>
    <span class="lettres"
      :style="{color:couleurTexte,'text-shadow':'1px 0 '+couleurTexteInv}"
      v-html="memberInitiales"
    ></span>
  </div>
</template>

<script>
export default {
  props: ["member", "initiales"],
  computed: {
    memberInitiales() {
      let ret = false;
      if (this.initiales) {
        ret = this.initiales;
      } else {
        let tmp = this.member.split(" ");
        if (tmp.length > 1) {
          ret = tmp[0].substr(0, 1) + "" + tmp[1].substr(0, 1);
        } else {
          ret = this.member.substr(0, 2);
        }
      }
      if (ret) {
        ret =
          "<span>" +
          ret.substr(0, 1) +
          "</span><span>" +
          ret.substr(1, 1) +
          "</span>";
        return ret;
      }
    },
    couleurFond() {
      return this.stringToColour(this.member);
    },
    couleurTexte() {
      return this.textColor(this.couleurFond);
    },
    couleurTexteInv() {
      return this.colorInverse(this.couleurTexte);
    },
  },
};
</script>

<style lang="scss">
.avatar-initiales {
  text-align: center;
  position: relative;
}
.avatar-initiales {
  .fond {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
  }
  .lettres {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-transform: uppercase;
    font-size: 110%;
    letter-spacing: 0.01em;
    font-family: "cooper black";

    span {
      transform: translateY(-50%);
      position: absolute;
      width: 50%;
      // height: 100%;
      top: 50%;
      &:nth-child(1) {
        z-index: 4;
        left: 1px;
        text-align: right;
      }
      &:nth-child(2) {
        z-index: 3;
        right: 1px;
        text-align: left;
      }
    }
  }
}
</style>