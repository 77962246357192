<template>
  <div class="message">
    <div class="message-header">
      <p>Aide</p>
      <button class="delete" type="button" aria-label="delete" @click="$bus.$emit('masquer-aide')"></button>
    </div>
    <div class="message-body">
      <div class="content">
        <h2>Un peu d'aide sur la rédaction des commentaires</h2>
        <p>Vous avez à votre disposition différents outils pour enrichir vos commentaires</p>
        <h3>Mise en forme</h3>
        <p>
          Vous pouvez utiliser
          <a
            href="https://fr.wikipedia.org/wiki/BBCode"
            target="_blank"
          >la syntaxe BBCode</a> pour mettre en forme vos messages
        </p>
        <h4>Balises BBCode disponibles</h4>
        <ul>
          <li>
            <code>[b]</code> Mettre le texte en gras -
            <code>[b]Allez les bleus[/b]</code>
          </li>
          <li>
            <code>[i]</code> Mettre le texte en italique -
            <code>[i]Allez les bleus[/i]</code>
          </li>
          <li>
            <code>[s]</code> Barrer le texte -
            <code>[s]Allez les bleus[/s]</code>
          </li>
          <li>
            <code>[u]</code> Souligner le texte -
            <code>[u]Allez les bleus[/u]</code>
          </li>
          <li>
            <code>[code]</code> Bloc avec police à espace fixe -
            <code>[code]Allez les bleus[/code]</code>
          </li>
          <li>
            <code>[img]</code> Afficher une image -
            <code>[img]https://www.asnl.net/img/logo_asnl.png[/img]</code>
          </li>
          <li>
            <code>[quote]</code> Citer un morceau de texte -
            <code>[quote]Allez les bleus[/quote]</code>
          </li>
          <li>
            <code>[spoiler]</code> Balise spoiler (le texte sera masqué par défaut) -
            <code>[spoiler]Allez les bleus[/spoiler]</code>
          </li>
          <li>
            Faire une liste d'élements. Exemple :<br><code>* Allez<br>* les<br>* bleus</code>
          </li>
        </ul>
        <h3>Images</h3>
        <p>
          Vous pouvez automatiquement copier/coller du contenu image dans la zone de texte de saisie. Un tag BBCode
          <code>[img]...[/img]</code> sera alors automatiquement ajouté à votre message.
        </p>
        <h3>Citation</h3>
        <p>
          Pour citer une portion d'un commentaire, sélectionnez le texte que vous voulez citer, puis cliquez sur le bouton "répondre à ce message". Un tag BBCode
          <code>[quote]...[/quote]</code> sera alors automatiquement ajouté à votre réponse.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentAide",
};
</script>

<style>
</style>