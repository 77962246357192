<template>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <p>
            <b>{{leMessage}}</b>
          </p>
          <div class="animated-ellipsis">Veuillez patienter</div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  props : ['message'],
  computed : {
    leMessage() {
      if(this.message) {
        return this.message;
      } else {
        return 'Chargement des commentaires';
      }
    }
  }
}
</script>

<style>
.animated-ellipsis:after {
  overflow: hidden;
  display: inline;
  animation: ellipsis 900ms infinite;
  content: "...";
}

@keyframes ellipsis {
  0% {
    content: ".";
  }
  30% {
    content: "..";
  }
  60% {
    content: "...";
  }
}
</style>